type Question = {
  id: number;
  message: string;
};
export const QUESTIONS: Question[] = [
  { id: 1, message: "기록을 하는 이유는 무엇인가요?" },
  { id: 2, message: "아침에 눈을 뜨면 가장 먼저 어떤 생각을 하나요?" },
  { id: 3, message: "당신이 제일 사랑하는 공간은 어디인가요?" },
  { id: 4, message: "이번 겨울에 하고 싶은 일이 있나요?" },
  { id: 5, message: "좋아하는 것과 사랑하는 것은 어떻게 다른가요?" },
  { id: 6, message: "사랑 후에는 어떤 것이 오나요?" },
  { id: 7, message: "오늘이 생의 마지막 날이라면 어떤 일을 하고 싶은가요?" },
  { id: 8, message: "당신을 가장 힘들게 하는 일은 무엇인가요?" },
  { id: 9, message: "당신을 가장 행복하게 하는 일은 무엇인가요?" },
  { id: 10, message: "지구에서 봄이 사라진다면 어떨까요?" },
  { id: 11, message: "하루를 마치고 집에 돌아오면 어떤 생각을 하나요?" },
  { id: 12, message: "세 가지 소원을 빌 수 있다면 어떤 소원을 빌고 싶나요?" },
  { id: 13, message: "인류 최고의 발명품이 무엇이라고 생각하나요?" },
  { id: 14, message: "가장 좋아하는 음식이 무엇인가요? 어떤 맛인가요?" },
  { id: 15, message: "눈을 감으면 어떤 것이 보이나요? 보이지 않나요?" },
  { id: 16, message: "내리는 비를 흠뻑 맞고 싶은 날이 있나요? 어떤 날인가요?" },
  { id: 17, message: "시간을 돌릴 수 있다면 언제로 돌아가고 싶나요? 이유는?" },
  { id: 18, message: "유독 더웠던 올해 여름, 어떤 마음으로 보냈나요?" },
  { id: 19, message: "하루 중 가장 좋아하는 시간은 언제인가요?" },
  {
    id: 20,
    message: "인생에 CCTV가 있다면 저장하여 돌려보고 싶은 순간은 무엇인가요?",
  },
  { id: 21, message: "당신을 가장 귀찮게 하는 일은 무엇인가요?" },
  { id: 22, message: "자서전을 쓴다면 첫 문장으로 쓰고 싶은 것은 무엇인가요?" },
];
